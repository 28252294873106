<template>
  <div class="chat h-100">
    <!-- <JobWantedHead /> -->
    <el-row class="chat-card">
      <el-col
        :xs="8"
        :sm="8"
        :md="8"
        :lg="7"
        :xl="6"
        class="chat-card__left ta-c"
      >
        <!-- <header class="left-header">
          <el-input
            v-model="keyword"
            size="small"
            suffix-icon="el-icon-search"
            placeholder="搜索"
          />
        </header> -->
        <div class="contact" v-if="contactList.length">
          <el-row
            :class="['contact-item', { 'is-Checked': item.isChecked === true }]"
            v-for="item in contactList"
            :key="item.shop_user_job_message_id"
            @click.native="handleCurrentChat(item)"
          >
            <el-col class="avatar" :span="8">
              <img :src="item.pic" />
            </el-col>
            <el-col class="hr-info" :span="16">
              <el-row class="hrInfoTop">
                <el-col :span="7" class="hr-name">{{ item.name }}</el-col>
                <el-col :span="13" class="company-info">{{
                  item.job_title
                }}</el-col>
                <el-col :span="3" class="time">{{
                  item.messages.length > 0
                    ? conversion(
                        item.messages[item.messages.length - 1].created
                      )
                    : ""
                }}</el-col>
              </el-row>
              <div class="message">
                {{
                  item.messages.length > 0
                    ? item.messages[item.messages.length - 1].personage
                    : ""
                }}
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :xs="15" :sm="15" :md="15" :lg="16" :xl="17" class="chat-main">
        <el-row class="chat-main__header">
          <el-col :span="3" class="name">{{ currentContact.name }}</el-col>
          <el-col :span="7" class="messageInfo">
            <p>
              <span>{{ currentContact.age }}</span
              >|<span>{{ currentContact.job_age }}</span
              >|<span>{{ currentContact.occupation }}</span>
            </p>
            <p>
              <span>MBTI：</span>
              <span class="mbtiValue">{{ currentContact.MBTI }}</span>
            </p>
            <p>
              <span>霍兰德职业兴趣：</span>
              <span class="hldValue">{{ currentContact.HLD }}</span>
            </p>
            <div class="jobSalary">{{ currentContact.job_salary }}</div>
          </el-col>
          <el-col :span="10" class="experience">
            <el-row v-for="item in currentContact.work_data" :key="item.id">
              <el-col :span="12"
                >{{ item.start_time }}-{{ item.end_time }}</el-col
              >
              <el-col :span="12"
                >{{ item.work_title }}·{{ item.position }}</el-col
              >
            </el-row>
          </el-col>
          <el-col :span="4">
            <p class="jobTitle">沟通职位：{{ currentContact.job_title }}</p>
            <div class="resume" @click="handleadvertis">简历</div>
          </el-col>
        </el-row>
        <div class="chat-content">
          <section
            v-for="item in currentContact.messages"
            :class="[
              'chat-item',
              { 'is-left': item.personage },
              { 'is-right': item.company },
            ]"
            :key="item.shop_user_job_message_dialogue_id"
          >
            <div class="chat-item__main">
              <div class="chat-item__content">
                {{ item.personage || item.company }}
              </div>
            </div>
          </section>
        </div>
        <div class="send-message">
          <el-input
            type="textarea"
            cols="100"
            rows="5"
            placeholder="说点什么吧..."
            v-model="message"
          />
          <el-button type="primary" icon="el-icon-send" @click="send"
            >发送</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="advertisVisible" :modal="false">
      <div class="statistic">
        <el-statistic
          ref="statistic"
          @finish="handleResume"
          format="ss"
          :value="deadline"
          title="倒计时:"
          time-indices
        >
        </el-statistic>
      </div>
      <div>
        <div type="primary" class="vipBtn">开通VIP免广告</div>
      </div>
    </el-dialog>

    <el-dialog title="简历" :visible.sync="resumeVisible" :modal="false">
      <div class="col-center">
        <div class="main-top">
          <div class="personal-data">
            <div class="data-name">
              {{ resume.personal_details.name }}
            </div>
            <div class="data-position">
              求职意向：{{ resume.personal_details.position }}
            </div>
            <div class="grade-location">
              <span class="data"
                >学历：{{ resume.personal_details.grade }}</span
              >
              <span>地址：{{ resume.personal_details.location }}</span>
            </div>
            <div class="phone-email">
              <span class="data"
                >电话：{{ resume.personal_details.phone }}</span
              >
              <span>邮箱：{{ resume.personal_details.email }}</span>
            </div>
          </div>
          <div class="personal-img">
            <el-image
              class="img"
              :src="resume.personal_details.img"
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div class="main-bottom">
          <div class="main-data">
            <div class="education-img">
              <span class="title">教育背景</span>
            </div>
            <div
              class="education-span"
              v-for="item in resume.education_data"
              :key="item.id"
            >
              <span>{{ item.college }}</span>
              <span>{{ item.start_time }} - {{ item.end_time }}</span>
              <span>{{ item.specialty }}</span>
              <span>{{ item.education }}</span>
              <span>{{ item.institute }}</span>
            </div>
          </div>
          <div class="main-data">
            <div class="education-img">
              <span class="title">工作经历</span>
            </div>
            <div
              class="item-data"
              v-for="item in resume.work_data"
              :key="item.id"
            >
              <div class="data-title">
                <span class="title-left">{{ item.work_title }}</span>
                <span>{{ item.start_time }} - {{ item.end_time }}</span>
              </div>
              <div class="data-content">
                <span class="content">{{ item.work_experience }}</span>
              </div>
            </div>
          </div>
          <div class="main-data">
            <div class="education-img">
              <span class="title">项目经验</span>
            </div>
            <div
              class="item-data"
              v-for="item in resume.project_data"
              :key="item.id"
            >
              <div class="data-title">
                <span class="title-left">{{ item.project_title }}</span>
                <span>{{ item.start_time }} - {{ item.end_time }}</span>
              </div>
              <div class="data-content">
                <span class="content">{{ item.project_experience }}</span>
              </div>
            </div>
          </div>
          <div class="main-data">
            <div class="education-img">
              <span class="title">技能优势</span>
            </div>
            <div class="item-data">
              <div class="data-content">
                <span class="content">{{ resume.skill_data }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Notification } from "element-ui";
// import { getUserMessage, insertDialogue } from "@/request/api";

import {
  insertDialogue,
  getJobMessage,
  getJobMessageUser,
} from "@/request/api";
// import JobWantedHead from "@/components/JobWantedHead.vue";
export default {
  name: "Chat",
  components: {
    // JobWantedHead,
  },
  data() {
    return {
      deadline: 0, // 倒计时
      resume: {}, // 简历数据
      resumeVisible: false, //简历弹窗控制
      advertisVisible: false, //广告弹窗控制
      keyword: "", // 搜索
      message: "",
      currentContact: {
        principal: "",
        chain_name: "",
        job_title: "",
        job_salary: "",
        job_salary_constitute: "",
        job_city: "",
        messages: [{}],
      },
      contactList: [
        {
          messages: [{}],
        },
      ],
      eventSource: null,
    };
  },
  watch: {
    message(value, oldValue) {
      console.log(value, "value");
      console.log(oldValue, "oldValue");
    },
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  },
  created() {
    this.getMessage();
    this.connectSSE();
  },
  methods: {
    connectSSE() {
      if (window.EventSource) {
        const usersinfomation = window.localStorage.getItem("usersinfomation");
        const ai_chain_id = JSON.parse(usersinfomation).ai_chain_id;

        this.eventSource = new EventSource(
          "https://www.bmbai.top/api/shopApi/chatGPT/SSEUser?ai_chain_id=" +
            ai_chain_id
        );
        this.eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (typeof data === "object" && data !== null) {
            let keys = Object.keys(data).length;
            if (keys !== this.contactList.length) {
              this.getMessage();
              return;
            }

            let i = 0;
            while (i < this.contactList.length) {
              const shop_user_job_message_id =
                this.contactList[i].shop_user_job_message_id;
              if (
                data[shop_user_job_message_id] &&
                data[shop_user_job_message_id] !==
                  this.contactList[i].messages.length
              ) {
                this.getMessage();
                break;
              }
              i++;
            }
          }
        };
      } else {
        console.log("Your browser doesn't support Server-Sent Events");
      }
    },
    async getMessage() {
      const res = await getJobMessage();
      if (res.data.code === "1000") {
        const messageList = res.data.data;
        this.contactList = messageList;
        this.contactList[0].isChecked = true;
        if (res.data.data[0]) {
          this.currentContact = res.data.data[0];
          this.resume = JSON.parse(res.data.data[0].resume);
        }

        const ret = await getJobMessageUser(res.data.data[0].shop_user_id);
        if (ret.data.code === "1000") {
          this.currentContact = { ...this.currentContact, ...ret.data.data[0] };
        }
      } else {
        this.$message({
          title: "出错了",
          message: res.data.msg,
          type: "error",
        });
      }
    },
    handleadvertis() {
      this.advertisVisible = true;
      this.deadline = Date.now() + 1000 * 15;
    },
    handleResume() {
      this.advertisVisible = false;
      this.resume = JSON.parse(this.currentContact.resume);
      this.resumeVisible = true;
      // this.currentContact.resume = JSON.parse(resumeMy.resume)
    },
    conversion(time) {
      const date = new Date(time);
      const h = date.getHours();
      const m = date.getMinutes();
      return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
    },
    send() {
      if (this.message.trim()) {
        this.currentContact.messages.push({
          role: "user",
          company: this.message,
          id: Date.now(),
          created: new Date().getTime(),
        });
        const body = {
          type: 1, //个人端
          shop_user_job_message_id:
            this.currentContact.shop_user_job_message_id, //消息id
          send: this.message,
        };
        insertDialogue(body)
          .then((res) => {
            if (res.data.code === "1000") {
              this.setContactList();
              this.message = "";
            }
          })
          .catch((error) => {
            this.$message({
              title: "发送失败",
              message: error.msg,
              type: "error",
            });
          });
      } else {
        Notification.warning({
          title: "警告",
          message: "消息内容不能为空",
        });
      }
    },
    // 除以1000,取整
    divSalary(count) {
      return Math.round(count / 1000);
    },
    // 保存当前对话列表 到缓存
    setContactList() {
      const index = this.contactList.findIndex(
        (item) =>
          item.shop_user_job_message_id ===
          this.currentContact.shop_user_job_message_id
      );
      if (index !== -1) {
        this.contactList.splice(index, 1, this.currentContact);
      } else {
        this.contactList.push(this.currentContact);
      }
      window.localStorage.setItem(
        "contactList",
        JSON.stringify(this.contactList)
      );
    },
    // 切换对话,切换对话前要先缓存当前对话
    async handleCurrentChat(item) {
      this.setContactList();
      console.log(item, "item");
      this.contactList = this.contactList.map((v) => {
        if (v.shop_user_job_message_id === item.shop_user_job_message_id) {
          v.isChecked = true;
        } else {
          v.isChecked = false;
        }
        return v;
      });
      const ret = await getJobMessageUser(item.shop_user_id);
      if (ret.data.code === "1000") {
        this.currentContact = { ...item, ...ret.data.data[0] };
      }
      // this.currentContact = item;
    },
    // 返回首页前，需要把当前对话列表存起来
    goHome() {
      this.setContactList();
      // 返回首页
      this.$router.push("/").catch((e) => {
        console.error(e);
      });
    },
    sendExit() {
      // 处理退出逻辑
    },
  },
};
</script>
<style lang="less" scoped>
.chat {
  .statistic {
    width: 100px;
    height: 40px;
    color: #828282;
  }
  .vipBtn {
    width: 100px;
    padding: 10px 20px;
    // height: 42px;
    line-height: 22px;
    background: #ffc047;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    margin: 0 auto;
  }
  .chat-card {
    background: transparent;
    display: flex;
    height: 92%;
    padding: 0;
  }

  .chat-card__left {
    height: 100%;
    // width: 300px;
    background: #fff;
    border-radius: 16px;
    overflow-x: auto;
    .left-header {
      padding: 10px;
      height: 50px;
      background-image: linear-gradient(to right, #d5e7fc, #eaf2f4);
    }
  }

  .contact {
    padding: 10px;
    .is-Checked {
      background: #f2f2f2;
    }
    .contact-item {
      height: auto;
      cursor: pointer;
      line-height: 55px;
      padding: 8px;
      margin-bottom: 10px;
      border-radius: 6px;

      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .hr-info {
        margin-left: 1em;
        text-align: left;
        font-size: 12px;
        line-height: 1.8;
        color: #666;
        .hrInfoTop {
          display: flex;
          align-items: flex-end;
        }
        .company-info {
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          white-space: nowrap; //文本不会换行
        }
        .hr-name {
          font-size: 14px;
          font-weight: bold;
          color: #333;
          // margin-right: 0.5em;
          align-items: flex-end;
        }
        .message {
          width: 66%;
          overflow: hidden;
          text-overflow: ellipsis; //文本溢出显示省略号
          white-space: nowrap; //文本不会换行
        }
      }

      &:hover {
        color: #027aff;
        background: #f3f3f3;
      }
    }
  }

  .chat-main {
    margin-left: 20px;
    // width: 720px;
    height: 100%;
    background: #fff;
    border-radius: 16px;

    .chat-main__header {
      padding: 16px;
      box-shadow: 0px 2px 5px 1px rgba(90, 90, 90, 0.16);
      background: #fff;
      border-radius: 8px 8px 8px 8px;
      .name {
        font-size: 18px;
        font-weight: 600;
      }

      .hr-info {
        color: #666;
        font-size: 12px;
      }
    }
    .messageInfo {
      font-size: 15px;
      color: #828282;
      > p {
        margin: 0;
        margin-bottom: 5px;
      }
      .mbtiValue,
      .hldValue {
        color: #000;
        font-weight: bold;
      }
      .jobSalary {
        width: 84px;
        color: #fff;
        background: #ffc047;
        padding: 5px 0px;
        text-align: center;
        border-radius: 14px 14px 14px 14px;
        font-weight: bold;
        // line-height: 20px;
      }
    }
    .experience {
      text-align: center;
    }
    .jobTitle {
      color: #2a6ee9;
    }
    .resume {
      width: 90px;
      line-height: 35px;
      background: #5eb8ff;
      color: #fff;
      text-align: center;
      cursor: pointer;
      border-radius: 8px 8px 8px 8px;
    }
    .job-info {
      margin: 1em;
      padding: 0 1em;
      background-image: linear-gradient(to right, #d7e9f7, #f2f2f2);
      line-height: 50px;
      border-radius: 8px;

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-right: 3em;
      }

      .salary {
        font-size: 20px;
        font-weight: bold;
        color: #fdb228;
        margin-right: 2em;
      }

      .city {
        font-size: 16px;
      }
    }

    .chat-content {
      height: 52%;
      padding-bottom: 20px;
      overflow-x: auto;
      .chat-item {
        --position: left;
        --avatar-bg: #dfe8fa;
        --content-bg: #f3f3f3;
        --content-color: #404040;
        margin: 1em 1em 0;
        overflow: hidden;

        .chat-item__main {
          float: var(--position);
          margin: 0 1em;
          max-width: 70%;
        }

        .chat-item__content {
          position: relative;
          width: 100%;
          line-height: 1.8;
          font-size: 15px;
          border-radius: 10px;
          color: var(--content-color);
          padding: 0.5em 1em;
          background: var(--content-bg);
          white-space: normal;
          word-break: break-all;
        }

        .chat-item__translate {
          margin-top: 0.5em;
          color: #666;
          width: 100%;
          line-height: 1.6;
          font-size: 14px;
          padding: 0.5em 1em;
          border-radius: 4px;
          white-space: normal;
          word-break: break-all;
          background: #fff;

          .translate-tip {
            color: #999;
            margin-top: 8px;

            .el-icon-success {
              margin-right: 10px;
            }
          }
        }

        &.is-left {
          .chat-item__content:before {
            position: absolute;
            top: 1em;
            left: -6px;
            border: 6px solid transparent;
            border-left: 0;
            border-right-color: var(--content-bg);
            content: "";
          }
        }

        &.is-right {
          --position: right;
          --avatar: url("https://photo.tuchong.com/14510776/f/596366497.jpg");
          --avatar-bg: #027aff;
          --content-bg: #027aff;
          --content-color: #fff;

          .chat-item__content:before {
            position: absolute;
            top: 1em;
            right: -6px;
            border: 6px solid transparent;
            border-right: 0;
            border-left-color: var(--content-bg);
            content: "";
          }
        }
      }
    }

    .send-message {
      position: relative;
      height: 117px;
      text-align: center;

      ::v-deep .el-textarea .el-textarea__inner {
        border-radius: 0 0 16px 16px;
        resize: none;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }

      .el-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }

  .col-center {
    height: 100%;
    display: flex;
    flex-direction: column;
    .main-top {
      min-height: 28%;
      display: flex;
      .personal-data {
        width: 76%;
        display: flex;
        flex-direction: column;
        padding: 30px 50px;
        box-sizing: border-box;
        .data-name {
          font-size: 22px;
          margin-bottom: 14px;
          font-weight: bold;
        }
        .data-position {
          margin-bottom: 10px;
        }
        .grade-location {
          margin-bottom: 10px;
          display: flex;
          font-size: 14px;
        }
        .data {
          width: 40%;
        }
        .phone-email {
          display: flex;
          font-size: 14px;
        }
      }
      .personal-img {
        flex: 1;
        display: flex;
        align-items: center;
        .img {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          border: 2px solid #dfdfdf;
        }
      }
    }
    .main-bottom {
      flex: 1;
      padding: 28px;
      box-sizing: border-box;
      .main-data {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        .education-img {
          height: 60px;
          font-size: 20px;
          display: flex;
          align-items: center;
          .title {
            width: 100px;
            text-align: center;
            border-left: 5px solid #2a6ee9;
          }
        }
        .item-data {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          .data-title {
            display: flex;
            font-weight: bold;
            margin-bottom: 4px;
            .title-left {
              margin-right: 10px;
            }
          }
          .data-content {
            padding: 4px;
            font-size: 14px;
            .content {
              white-space: pre-wrap;
            }
          }
        }
        .education-span {
          display: flex;
          font-size: 14px;
          justify-content: space-between;
          font-weight: bold;
          margin-bottom: 10px;
          height: 50px;
          align-items: center;
        }
      }
    }
  }
}
</style>
